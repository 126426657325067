import './index.css';

function App() {
  return (
        <div >
      <div className="content">
        <h1 className="title">Wartungsmodus</h1>
        <p className="text">Entschuldigen Sie bitte die Unannehmlichkeiten. Diese Seite befindet sich derzeit in Wartung. Wir arbeiten daran, sie so schnell wie möglich wieder online zu bringen.</p>
        <p className="text">Danke für Ihr Verständnis.</p>
        <p className='text-logo'>Tech-Performance-Design</p>
      </div>
    </div>
  );
}

export default App;
